<template>
  <div>
    <div class="content-header cols">
      <div>
        <h2>{{ $tfo('external,,1 | events') }}</h2>
      </div>
      <div class="content-header-buttons"></div>
    </div>

    <div name="table-pagination" class="table-pagination mar-v--1 cols">
      <div class="cell"></div>
      <div class="cell--s">
        <i class="el-icon-loading" v-if="state.playing && $store.state.p_server.connected"></i>
        <el-button name="play-btn" size="small" type="primary" v-if="!state.playing" @click="togglePlaying" :disabled="!!state.filter.current.page"
          ><i class="fa fa-play"></i
        ></el-button>
        <el-button name="pause-btn" size="small" :plain="true" v-else @click="togglePlaying"><i class="fa fa-pause"></i></el-button>
        <el-button name="set-all-acknowledged-btn" class="mar-left--0-5" size="small" :plain="true" @click="setAllAcknowledged">
          <i class="fa fa-check"></i>
          <span class="mar-left--0-5">{{ $tfo('acknowledge_v | all') }}</span>
        </el-button>
      </div>
      <div class="cell-s">
        <el-select
          name="limit"
          class="filter--page-limit mar-left--1"
          v-model="state.filter.current.limit"
          :disabled="state.loading"
          v-if="state.limits"
          size="small"
        >
          <el-option :key="item" :value="item" :label="item" v-for="item in state.limits"></el-option>
        </el-select>
      </div>

      <table-navigation :state="state"></table-navigation>
    </div>

    <el-table
      :name="model.name + '-table'"
      :class="model.name + '-table'"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      :row-key="'id'"
      @row-click="rowClick"
    >
      <el-table-column prop="id" :label="$tf('id')" width="100"></el-table-column>

      <el-table-column prop="" :label="$tf('common.detected')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail
            :key="'thumb-' + row.id"
            :url="row.thumbnail || ''"
            :frame="row.fullframe || ''"
            @preview="(v) => showImage(v && v.src, getBox(row))"
          ></object-thumbnail>
        </template>
      </el-table-column>

      <el-table-column prop="" :label="$tf('common.matched_to')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail
            :key="'face-' + row.id"
            :id="row.face_object_id"
            :requiredId="!!row.face_object_id"
            @preview="(v) => showImage(v && v.src, null)"
          ></object-thumbnail>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="" min-width="240px">
        <template slot-scope="{ row }">
          <dossier-table-part-item
            v-if="row.dossier"
            :key="'item-' + row.dossier"
            :confidence="row.confidence"
            :by-dossier-id="row.dossier"
          ></dossier-table-part-item>
          <div v-else>
            {{ $tf('no | matches,,0') }}
          </div>
          <div class="event-reaction-content">
            <event-reaction :item="row" :model="model.name" :cancelable="false"></event-reaction>
          </div>
        </template>
      </el-table-column>

      <el-table-column width="160px" :label="$tf('date')">
        <template slot-scope="{ row }">
          {{ row.created_date | isoStringToDate | formatDate }}<br />
          {{ row.created_date | isoStringToDate | formatTime }}
        </template>
      </el-table-column>

      <el-table-column width="120px" :label="$tf('camera')">
        <template slot-scope="{ row }">
          {{ row.camera }}
          <br />
          <span class="text-tetriary">
            {{ row.puppet_id }}
          </span>
        </template>
      </el-table-column>
    </el-table>

    <div name="table-pagination" class="table-pagination pull-right mar-v--1">
      <table-navigation :state="state"></table-navigation>
    </div>
  </div>
</template>

<script>
import ObjectThumbnail from '../../objects/thumbnail.vue';
import DossierListsInline from '../../dossier-lists/inline.vue';
import EventReaction from '@/components/events/reaction.vue';
import DossiersItem from '@/components/events/dossier-item.vue';
import TableNavigation from '@/components/tables/navigation.vue';
import DossierTablePartItem from '@/components/puppeteer/search-events/dossier.item';

export default {
  components: {
    TableNavigation,
    DossierTablePartItem,
    ObjectThumbnail,
    DossierListsInline,
    DossiersItem,
    EventReaction
  },
  name: 'page-remote-monitoring-events',
  data: function () {
    return {
      model: {
        name: 'puppeteer_remote_monitoring_events',
        route: 'puppeteer/remote-monitoring',
        permissions: {
          create: 'ffsecurity.add_remote-monitoring'
        }
      }
    };
  },
  computed: {
    state() {
      return this.$store.state.puppeteer_remote_monitoring_events;
    }
  },
  mounted() {},
  methods: {
    setAllAcknowledged() {
      this.$confirm(this.$tf(['common.set_all_acknowledged_confirm']), this.$tf(['common.warning']), {
        confirmButtonText: this.$tf(['common.ok']),
        cancelButtonText: this.$tf(['common.cancel']),
        type: 'warning'
      })
        .then(() => {
          this.$store
            .dispatch(this.$store.state.ActionNames.PuppeteerRemoteMonitoringEvents.AcknowledgeAll)
            .then((v) => {
              this.$notify({ type: 'success', message: this.$tf(['common.action', 'common.success']) });
            })
            .catch((e) => {
              this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
            })
            .finally(() => {
              this.$store.dispatch(this.state.Action.Get);
            });
        })
        .catch((e) => {
          console.warn('[acknowledge] was cancelled');
        });
    },
    togglePlaying() {
      this.state.playing = !this.state.playing;
    },
    rowClick(item) {
      /*
        if (this.$store.state.app.key.ctrl) return
        this.$router.push({path: this.getItemLink(item)})
         */
    },
    getItemLink(item) {
      return `/${this.model.route}/` + encodeURIComponent(item.id) + '/';
    },
    showImage(url, box) {
      this.$store.dispatch('showImage', { src: url, box: box });
    },
    getBox(v) {
      return {
        x: v.bbox.left,
        y: v.bbox.top,
        w: v.bbox.right - v.bbox.left,
        h: v.bbox.bottom - v.bbox.top
      };
    }
  }
};
</script>
