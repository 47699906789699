<template>
  <div class="height-100">
    <div class="flex-cell rows" style="justify-content: space-between" v-if="!loading">
      <a v-if="dossier" :href="getDossierUrl(dossier)" class="text-xl search-item-link" @click.stop.prevent="dossierClickHandler(dossier)">
        <span>{{ dossier.name | shortString }}</span
        >&nbsp;<span v-if="confidence > 0">({{ confidence | floatToPercent }})</span>
      </a>
      <div class="search-item-text--large" v-else>{{ $tfo('not_found,,2') }}</div>

      <div v-if="dossier && dossier.dossier_lists" class="dossier-lists-container">
        <span
          class="dossier-list-item text-s"
          :style="{ backgroundColor: '#' + list.color, color: getFontColor(list) }"
          v-for="list in getDossierLists(dossier.dossier_lists)"
        >
          {{ list.name | shortString }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import Color from 'color';
import Styles from '@/guide/components/styles';

export default {
  name: 'dossier-table-part-item',
  components: { Styles },
  props: {
    byDossierFaceId: {
      type: String
    },
    byDossierId: {
      type: Number
    },
    confidence: {
      type: Number
    }
  },
  data() {
    return {
      loading: false,
      dossier: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    getFontColor(item) {
      let color = Color('#' + item.color),
        isLight = color.light();
      return isLight ? '#000' : 'rgba(255, 255, 255, 0.8)';
    },
    getDossierLists(v) {
      return v.map((id) => this.$store.state.dossier_lists.items.find((v) => v.id === id));
    },
    load() {
      let getDossierIdPromise = null;
      this.loading = true;

      if (this.byDossierFaceId) {
        getDossierIdPromise = this.$store.dispatch('getBatchObjectFace', { id_in: this.byDossierFaceId }).then((v) => v.dossier);
      } else {
        getDossierIdPromise = Promise.resolve(this.byDossierId);
      }

      getDossierIdPromise
        .then((v) => {
          return this.$store.dispatch('get_dossiers', { id: v });
        })
        .then((dossier) => {
          this.dossier = dossier;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDossierUrl(v) {
      return '#/dossiers/' + v.id;
    },
    dossierClickHandler(v) {
      this.$router.push({ path: '/dossiers/' + v.id });
    }
  }
};
</script>

<style lang="stylus">

.dossier-lists-container {
  margin-top: 1rem;
}

.dossier-list-item {
  display: inline-block;
  margin-right: 0.5rem;
  background-color: gray;
  color: white;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 4px;
}
</style>
